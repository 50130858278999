body {
  padding: 0;
  background-color: #23252C;
  font-family: 'Inter', sans-serif;
  color: white;
  padding-top: 5em;
}

.container {
  width: 317px;
  margin-left: auto;
  margin-right: auto;
  background-color: #1A1B21;
  border-radius: 1em 1em 1em 1em;
}

.Info--profile_picture {
  width: 317px;
  border-radius: 1em 1em 0 0;
}

.Info--details_container {
  margin-top: -4px;
  text-align: center;
}

button > i {
  font-size: 15px;
  padding-right: 5px;
}

.Info--name {
  font-size: 25px;
  font-weight: 700;
  margin: 0;
  padding-top: 1em;
}

.Info--position {
  color: #F3BF99;
  font-size: 12.8px;
  margin: 0;
  padding-top: 0.2em;
  font-weight: 400;
}

.Info--website {
  font-size: 10px;
  font-weight: 400;
  margin-top: 1em;
}

.Info--buttons {
  padding-top: 1em;
  display: flex;
  padding: 0 40px;
  margin-top: 1em;
}

button {
  width: 110px;
  height: 34px;
  border-radius: 0.4em;
  border: none;
  font-weight: 600;
  background-color: #D1D5DB;
}

.Info--email:hover {
  background-color: white;
}

.Info--linkedin:hover {
  background-color: #619bdd;
}

.Info--email {
  margin-right: auto;
}

.Info--linkedin {
  background-color: #5093E2;
  color: white;
}

.About--container {
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  min-height: 150px;
  margin-top: 2em;
}

h3 {
  margin: 0;
}

.About--title {
  font-size: 16px;
  margin-top: 2em;
  font-weight: 600;
}

.About--description {
  font-size: 10px;
  font-weight: regular;
}

.Footer--container {
  background-color: #161619;
  border-radius: 0 0 1em 1em;
  text-align: center;
  padding: 1em 0;
}

.Footer--button {
  position: relative;
  color: gray;
  font-size: 25px;
}